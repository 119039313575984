









































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CvvTooltip',
  components: {
    SfImage
  },
  props: {
    cvvImageUrl: {
      required: true,
      type: String
    }
  },
  setup() {
    const isShowContent = ref(false);

    const toggleTooltip = () => {
      isShowContent.value = !isShowContent.value;
    };

    return {
      isShowContent,
      toggleTooltip
    };
  }
});
