



































































































































import { useGmoPayment, useOrder, usePayment } from '@checkout/composables';
import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfImage, SfRadio } from '@storefront-ui/vue';

export default defineComponent({
  name: 'GmoPaymentProvider',
  components: {
    SfRadio,
    SfImage,
    CreditCardForm: () => import('./CreditCardForm.vue')
  },
  props: {
    beforePay: {
      type: Function as PropType<() => void>,
      default: () => undefined
    },
    afterPay: {
      type: Function as PropType<(orderNumber: string) => void>,
      default: () => undefined
    },
    onError: {
      type: Function as PropType<(message: string) => void>,
      default: () => undefined
    }
  },
  emits: ['status'],
  setup(props, { emit }) {
    const { i18n } = useContext();
    const { selectedPaymentMethodCode, setPaymentMethod } = usePayment();
    const {
      placeOrder,
      errors: { placeOrder: placeOrderError }
    } = useOrder();

    const {
      paymentMethods,
      storedPaymentMethods,
      getPaymentMethods,
      loadPaymentStatus,
      errors: { loadPaymentStatus: loadPaymentStatusError }, //gmoError
      paymentMethodsConfig
    } = useGmoPayment();

    const paymentMethod = computed(() => paymentMethods.value?.[0] ?? null);

    const CcVaultFormRefs = ref([]);
    const CcFormRef = ref(null);

    const isGmoGroupChecked = computed(() =>
      selectedPaymentMethodCode.value?.startsWith('np_gmo_payment_credit_card')
    );

    onMounted(() => {
      getPaymentMethods();
    });

    const definePaymentMethods = async (paymentMethodCode: string) => {
      emit('status', paymentMethodCode);
      await setPaymentMethod(paymentMethodCode);
    };

    const getVaultConfig = (details) => {
      return {
        ...paymentMethodsConfig.value,
        paymentDetails: details
      };
    };

    const triggerGmoPayment = async () => {
      if (
        selectedPaymentMethodCode.value?.startsWith(
          'np_gmo_payment_credit_card_vault'
        )
      ) {
        const vaultId = selectedPaymentMethodCode.value?.replace(
          'np_gmo_payment_credit_card_vault_',
          ''
        );
        const refIdx = CcVaultFormRefs.value?.findIndex(
          (item) => item.cardId === vaultId
        );
        await CcVaultFormRefs.value?.[refIdx]?.useGmoPayment();
      } else {
        await CcFormRef.value.useGmoPayment();
      }
    };

    const beforeSavePayment = () => {
      props.beforePay();
    };

    const afterSavePayment = async (isError: boolean) => {
      if (!isError) {
        const order = await placeOrder();
        if (order) {
          // Load payment status to check if 3D Secure is needed
          const paymentStatus = await loadPaymentStatus(order?.order_number);
          if (loadPaymentStatusError.value?.message) {
            return props.onError(loadPaymentStatusError.value?.message);
          }
          if (!paymentStatus.isFinal) {
            // If 3D Secure URL exists, redirect to it
            window.location.href = paymentStatus.ccAcsUrl;
            return;
          }

          // If no 3D Secure needed or already completed, proceed with success
          return props.afterPay(order.order_number);
        }
        if (placeOrderError.value) {
          return props.onError(placeOrderError.value.message);
        }
      }
      return props.onError(
        i18n.t('checkout.payment.errors.refused_payment') as string
      );
    };

    const isShowContent = (method) => {
      return selectedPaymentMethodCode.value === method;
    };

    const resetPaymentMethod = () => {
      definePaymentMethods('np_gmo_payment_credit_card');
    };

    return {
      paymentMethod,
      storedPaymentMethods,
      selectedPaymentMethodCode,
      paymentMethodsConfig,
      definePaymentMethods,
      isGmoGroupChecked,
      resetPaymentMethod,
      isShowContent,
      triggerGmoPayment,
      getVaultConfig,
      CcVaultFormRefs,
      CcFormRef,
      afterSavePayment,
      beforeSavePayment
    };
  }
});
