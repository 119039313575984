/** GraphQL query to load customer payment token. */

export default `
  query gmoCustomerPaymentTokens {
    gmoCustomerPaymentTokens {
      items {
        icon {
          url
          width
          height
          title
        }
        type
        publicHash
        maskCardNumber
        expiryMonth
        expiryYear
      }
    }
  }
`
