/** GraphQL mutation to delete vault card. */

export default `
  mutation gmoDeletePaymentToken($public_hash: String!) {
    gmoDeletePaymentToken(public_hash: $public_hash) {
      success
      customerPaymentTokens {
        items {
          icon {
            url
            width
            height
            title
          }
          type
          publicHash
          maskCardNumber
          expiryMonth
          expiryYear
        }
      }
    }
  }
`
