/** GraphQL mutation to handle threeDSecure return. */

export default `
  mutation gmoPaymentThreeDSecureReturn($access_id: String!) {
    gmoPaymentThreeDSecureReturn(access_id: $access_id) {
      orderNumber
      success
      message
    }
  }
`;
