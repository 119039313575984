/** GraphQL query to get gmo payment status. */

export default `
  query gmoPaymentStatus($order_number: String!) {
    gmoPaymentStatus(order_number: $order_number) {
      isFinal
      ccAcsUrl
    }
  }
`;
