import {computed, readonly, Ref, ref} from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';

import { useApi } from '~/composables/useApi';
import useCart from '~/modules/core/composables/useCart';

import customerPaymentTokensQuery from '../../queries/customerPaymentTokens';
import deletePaymentTokenMutation from '../../queries/deletePaymentToken';
import paymentMethodsQuery from '../../queries/paymentMethods';
import paymentStatusQuery from '../../queries/paymentStatus';
import threeDSecureReturnMutation from '../../queries/threeDSecureReturn';
import type {
  CustomerPaymentTokensResult,
  DeletePaymentTokensResult,
  PaymentMethodsResult,
  PaymentStatusResult,
  ThreeDSecureReturnStatusResult,
  PaymentStatusData,
  ThreeDSecureReturnStatusData,
  UseGmoPayment
} from './types';

export const LOGGER_PREFIX = '[GMO]';

export const useGmoPayment = (): UseGmoPayment => {
  const { query, mutate } = useApi();
  const { cart } = useCart();

  const paymentMethodsData = ref(null);
  const paymentStatusData = ref<PaymentStatusData | null>(null);
  const threeDSecureReturnStatusData = ref<ThreeDSecureReturnStatusData | null>(null);
  const vaultCards = ref([]);
  const loading = ref(false);
  const deleting = ref(false);
  const error = ref({
    loadPaymentMethods: null,
    deletePaymentToken: null,
    loadCustomerPaymentTokens: null,
    loadPaymentStatus: null,
    threeDSecureReturn: null,
  });

  const getError = (err) => err.response || err.data || err;

  const loadCustomerPaymentTokens = async () => {
    try {
      loading.value = true;
      const { data }: CustomerPaymentTokensResult = await query(customerPaymentTokensQuery);

      if (data?.gmoCustomerPaymentTokens?.items) {
        vaultCards.value = data.gmoCustomerPaymentTokens.items;
      }
    } catch (err) {
      error.value.loadCustomerPaymentTokens = getError(err);
      Logger.error(`${LOGGER_PREFIX} ${err.message}`);
    } finally {
      loading.value = false;
    }
  };

  const deletePaymentToken = async (hash) => {
    try {
      deleting.value = true;
      error.value.deletePaymentToken = null;

      const { data, errors } = await mutate<DeletePaymentTokensResult['data']>(deletePaymentTokenMutation, {
        public_hash: hash
      });

      if (errors) {
        throw errors[0];
      }

      if (data?.gmoDeletePaymentToken?.success) {
        vaultCards.value = data?.gmoDeletePaymentToken?.customerPaymentTokens?.items;
      }
    } catch (err) {
      error.value.deletePaymentToken = getError(err);
      Logger.error(`${LOGGER_PREFIX} ${err.message}`);
    } finally {
      deleting.value = false;
    }
  };

  const loadPaymentMethods = async () => {
    try {
      loading.value = true;
      const { data }: PaymentMethodsResult = await query(paymentMethodsQuery, {
        cart_id: cart.value.id
      });

      if (data?.gmoPaymentMethods) {
        paymentMethodsData.value = data?.gmoPaymentMethods;
      }
    } catch (err) {
      error.value.loadPaymentMethods = getError(err);
      Logger.error(`${LOGGER_PREFIX} ${err.message}`);
    } finally {
      loading.value = false;
    }
  };

  const loadPaymentStatus = async (orderNumber) => {
    try {
      loading.value = true;
      const { data, errors } = await query<PaymentStatusResult['data']>(paymentStatusQuery, {
        order_number: orderNumber,
      });

      if (errors) {
        throw errors[0];
      }

      if (data?.gmoPaymentStatus) {
        paymentStatusData.value = data?.gmoPaymentStatus;
      }
    } catch (err) {
      error.value.loadPaymentStatus = getError(err);
      Logger.error(`${LOGGER_PREFIX} ${err.message}`);
    } finally {
      loading.value = false;
    }
  };

  const threeDSecureReturn = async (accessId): Promise<ThreeDSecureReturnStatusResult['data'] | undefined> => {
    try {
      loading.value = true;

      const { data, errors } = await mutate<ThreeDSecureReturnStatusResult['data']>(threeDSecureReturnMutation, {
        access_id: accessId
      });

      if (errors) {
        throw errors[0];
      }

      return data
    } catch (err) {
      error.value.threeDSecureReturn = getError(err);
      Logger.error(`${LOGGER_PREFIX} ${err.message}`);
    } finally {
      loading.value = false;
    }
  };

  return {
    loadCustomerPaymentTokens,
    deletePaymentToken,
    loadPaymentMethods,
    loadPaymentStatus,
    threeDSecureReturn,
    vaultCards: computed(() => vaultCards.value),
    paymentMethodsData: computed(() => paymentMethodsData.value),
    paymentStatusData: computed(() => paymentStatusData.value),
    threeDSecureReturnStatusData: computed(() => threeDSecureReturnStatusData.value),
    loading: readonly(computed(() => loading.value)),
    deleting: readonly(computed(() => deleting.value)),
    error: readonly(computed(() => error.value))
  };
};
