/** GraphQL query to get gmo payment methods. */

export default `
  query gmoPaymentMethods($cart_id: String!) {
    gmoPaymentMethods(cart_id: $cart_id) {
      paymentMethods {
        title
        code
      }
      storedPaymentMethods {
        title
        code
        details {
          icon {
            url
            width
            height
            title
          }
          id
          type
          publicHash
          maskCardNumber
          expiryMonth
          expiryYear
        }
      }
      config {
        isDebug
        shopId
        use3DS
        cvvImageUrl
        endpointUrl
        ccTypes {
          code
          label
          icon {
            url
            width
            height
            title
          }
        }
        ccMethods {
          code
          label
        }
        ccPayTimes {
          code
          label
        }
        ccMonths {
          code
          label
        }
        ccYears {
          code
          label
        }
      }
    }
  }
`
