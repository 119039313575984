






import { useGmoPayment } from '@checkout/composables';
import {
  defineComponent,
  onMounted,
  ref,
  useRoute
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ThreeDSecureReturn',
  components: {
    SfLoader
  },
  middleware({ redirect, route }) {
    if (!route.query.AccessID) {
      return redirect('/');
    }
  },
  setup: () => {
    const route = useRoute();
    const { AccessID: accessId } = route.value.query;

    const loading = ref(true);
    const { verify3dSecure } = useGmoPayment();

    onMounted(async () => {
      if (accessId) {
        await verify3dSecure(accessId as string);
      }
    });

    return {
      loading
    };
  }
});
